import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.sass';
import johnson from '../../images/png/johnson.png';
import paper from '../../images/png/oldpaper2-filled2.png';
import logo501 from '../../images/png/logo501.png';
import logo101 from '../../images/png/logo101tissus.png';
import brevet from '../../images/png/brevet.png';
import oval501 from '../../images/png/oval501.png';

const Regiment = () => (
  <Row className={styles.container}>
    <Col xs={12} md={12} lg={4} xl={4} className={styles.left}>
      <div className={styles.logo501container}>
        <img className={styles.logo501} src={logo501} alt="logo501" />
        <div className={styles.brevets}>
          <img className={styles.brevet} src={brevet} alt="brevet" />
          <img className={styles.oval501} src={oval501} alt="oval501" />
        </div>
      </div>
      <div className={styles.logo101container}>
        <img className={styles.logo101} src={logo101} alt="logo101" />
      </div>
    </Col>
    <Col xs={12} md={12} lg={5} xl={4} className={styles.middle}>
      <img src={paper} alt="paperblock" />
    </Col>
    <Col xs={12} md={12} lg={3} xl={4} className={styles.right}>
      <img src={johnson} alt="johnson" />
    </Col>
  </Row>
);

export default Regiment;
