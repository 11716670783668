import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-responsive-modal';
import styles from './index.module.sass';

import ContactForm from './form';
import JoinsUsCard from '../JoinUs/card';

import typewriter from '../../images/png/typewriter.png';
import camera from '../../images/png/camera.png';
import facebookLogo from '../../images/socials/facebook.png';
import instargamLogo from '../../images/socials/instagram.png';
import youtubeLogo from '../../images/socials/youtube.png';

const Contact = () => {
  const [isOpen, setisOpen] = useState(false);

  const handleClick = value => setisOpen(value);

  return (
    <React.Fragment>
      <Row className={styles.container}>
        <Col xs={12} md={12} lg={4} className={styles.cameraContainer}>
          <a href="/photos">
            <img src={camera} className={styles.picture} alt="charge" />
          </a>
          <h1>Photographies</h1>
        </Col>
        <Col xs={12} md={12} lg={4} className={styles.socialsContainer}>
          <h2>Suivez-nous</h2>
          <div className={styles.socials}>
            <a
              href="https://www.facebook.com/KLONDIKEgroup/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={facebookLogo}
                className={styles.facebook}
                alt="facebook"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCtJhrnjK79OpZc3K_Gwna8g"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={youtubeLogo} className={styles.youtube} alt="youtube" />
            </a>
            <a
              href="https://www.instagram.com/klondikegroup/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={instargamLogo}
                className={styles.instagram}
                alt="instagram"
              />
            </a>
          </div>
          <div className={styles.facebookLike}>
            <iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FKLONDIKEgroup%2F&width=117&layout=button_count&action=like&size=large&share=false&height=21&appId"
              style={{
                width: 117,
                height: 35,
                border: 'none',
                overflow: 'hidden',
                scrolling: 'no',
                frameborder: '0',
                allowTransparency: 'true',
                allow: 'encrypted-media',
              }}
            />
          </div>
        </Col>
        <Col xs={12} md={12} lg={4} className={styles.typewriterContainer}>
          <Modal
            open={isOpen}
            onClose={() => handleClick(false)}
            className={styles.contactModal}
          >
            <ContactForm />
          </Modal>
          <div className={styles.contact}>
            <h1>Contactez-nous !</h1>
            <img
              className={styles.typewriter}
              src={typewriter}
              alt="typewriter"
              onClick={() => handleClick(true)}
            />
          </div>
          <div className={styles.mailContainer}>
            <div className={styles.mail}>contact.klondikegroup@gmail.com</div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Contact;
