import React from 'react';
import { Col, Row } from '../Grid';
import styles from './index.module.sass';
import logoklondike from '../../images/png/logoklondike2.png';

const parts = [
  {
    title: "Qui sommes-nous ?",
    description: "Passionnés d'Histoire, nous sommes un groupe de reconstitution historique retraçant le parcours du 501st PIR à travers la seconde guerre mondiale. Venant de tous horizons, nous nous sommes regroupés autour d'une même passion pour former le Klondike Group.",
  },
  {
    title: "Le 501st PIR ?",
    description: "Le 501st PIR est un régiment d'infanterie parachutiste rattaché à la 101st Airborne américaine. Déployé en Europe dès janvier 1944, ces hommes participeront à la campagne de l'Ouest du débarquement en Normandie le 6 juin 1944 jusqu'à la capitulation allemande en mai 1945.",

  },
  {
    title: 'Que faisons-nous ?',
    description: "Reconstitution immersive, camps statiques publics, réalisation de court-métrages: nous vous faisons re-vivre les grandes campagnes de ce régiment tout au long de l'année."

  },
];

const Presentation = () => (
  <Row className={styles.container}>
    <Col xs={12} sm={12} md={4} lg={4} className={styles.center}>
      <h3>{parts[0].title}</h3>
      <div>{parts[0].description}</div>
    </Col>
    <Col xs={12} sm={12} md={4} lg={4} className={styles.center}>
      <img
        className={styles.logoKlondike}
        src={logoklondike}
        alt="logo-klondike"
      />
    </Col>
    <Col xs={12} sm={12} md={4} lg={4} className={styles.center}>
      <h3>{parts[2].title}</h3>
      <div>{parts[2].description}</div>
    </Col>
  </Row>
);

export default Presentation;
