import React from 'react';
import PropTypes from 'prop-types';

export const Col = props => {
  const { xs, lg, sm, md, className, children } = props;
  let columnsClass = `col-xs-${xs}`;
  if (sm) {
    columnsClass += ` col-sm-${sm}`;
  }
  if (lg) {
    columnsClass += ` col-sm-${lg}`;
  }
  if (md)
    columnsClass += ` col-md-${md}`;
  return <div className={`${columnsClass} ${className}`}>{children}</div>;
};

Col.defaultProps = {
  xs: 12,
  lg: null,
  sm: null,
  md: null,
  className: '',
};

Col.propTypes = {
  xs: PropTypes.number,
  lg: PropTypes.number,
  sm: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Col;
