import React from 'react';
import styles from './index.module.sass';

import Videos from '../Videos';

const C47 = () => (
  <div className={styles.divider}>
    <div className={styles.videos}>
      <Videos />
    </div>
  </div>
);

export default C47;
