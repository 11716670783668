import React from 'react';
import styles from './css/index.module.sass';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Regiment from '../components/Regiment';
import Presentation from '../components/Presentation';
import Contact from '../components/Contact';
import C47 from '../components/C47';
import Activities from '../components/Activities';
import JoinUsCard from '../components/JoinUs/card';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <React.Fragment>
      <section className={styles.fog}>
        <div className={styles.absoluteBg} />
        <div className={styles.fogContainer}>
          <div className={`${styles.fogImg} ${styles.fogImgFirst}`} />
          <div className={`${styles.fogImg} ${styles.fogImgSecond}`} />
        </div>
      </section>
      <div className={styles.paragraphs}>
        <div className={styles.center}></div>
        <Presentation />
        <Regiment />
        <Activities />
        <C47 />
        <JoinUsCard />
        <Contact />
      </div>
    </React.Fragment>
  </Layout>
);

export default IndexPage;
