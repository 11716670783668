import React from 'react';
import { useForm } from '@formcarry/react';
import check from '../../images/png/green_check.png';

import styles from './index.module.sass';

import doc from '../../images/reenact/doctype2.jpg';

const ContactForm = () => {
  const { state, submit } = useForm({
    id: 'Lz-29whU936x',
  });

  if (state.submitted) {
    return (
      <div className={styles.successContainer}>
        <div className={styles.sent}>
          <img src={check} alt="sent" />
          <span>Message envoyé</span>
        </div>
        <div className={styles.answer}>
          Nous vous répondrons dans les plus brefs délais
        </div>
        <img src={doc} alt="typewriter" className={styles.typeWriter} />
      </div>
    );
  }

  return (
    <div className={styles.form}>
      <h1>Contact</h1>
      <form onSubmit={submit}>
        <div className={styles.content}>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Adresse email"
            className={styles.mailInput}
            required
          />
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            className={styles.messageInput}
            rows="3"
            required
          />
          <button type="submit" className={styles.submit}>
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
