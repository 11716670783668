import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.sass';
import ReactPlayer from 'react-player/youtube';

const videosUrl = [
  'https://www.youtube.com/embed/sgsOLDD5qHY?controls=0',
  'https://www.youtube.com/embed/TsS_ZrdftbM?controls=0',
  'https://www.youtube.com/embed/NG7CpoDXGAI?controls=0',
];

const Videos = () => (
  <Row className={styles.container}>
    <div className={styles.videoWrapper}>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=tpZqcozDcn0&ab_channel=KLONDIKEGROUP"
        playing={true}
        loop={true}
        playbackRate={1}
        width={'100%'}
        height={'100%'}
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
    </div>
  </Row>
);

export default Videos;
