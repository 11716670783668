import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.sass';

import wright from '../../images/reenact/wright1.jpg';
import moore from '../../images/reenact/moore1.jpg';
import tournage from '../../images/reenact/tournage2.jpg';
import camp from '../../images/reenact/duclair.jpg';

import Divider from '../Tools/divider.jsx';

const Activities = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Row className={styles.display}>
        <Col xs={12} md={4} lg={4} className={styles.left}>
          <img src={wright} alt="wright" />
        </Col>
        <Col xs={12} md={4} lg={4} className={styles.middle}>
          <p>
            Nous faisons revivre les petites histoires individuelles et
            collectives qui ont construit l'Histoire avec un grand H...
          </p>
        </Col>
        <Col xs={12} md={4} lg={4} className={styles.right}>
          <img src={moore} at="moore" />
        </Col>
      </Row>
      <Divider />
      <Row className={styles.camps}>
        <Col xs={12} md={5} lg={5} className={styles.left}>
          <p>
            Venez nous rencontrer à l'occasion de camps publics, nous nous
            ferons un plaisir de partager notre passion avec vous
          </p>
        </Col>
        <Col xs={12} md={7} lg={7} className={styles.right}>
          <img src={camp} alt="camp" />
        </Col>
      </Row>
      <Divider />
      <Row className={styles.movies}>
        <Col xs={12} md={5} lg={5} className={styles.rightMobile}>
          <p>
            Nous participons à la réalisation de projets cinématographiques à
            petite et grande échelle
          </p>
        </Col>
        <Col xs={12} md={7} lg={7} className={styles.left}>
          <img src={tournage} alt="filming" />
        </Col>
        <Col xs={12} md={5} lg={5} className={styles.right}>
          <p>
            Nous participons à la réalisation de projets cinématographiques à
            petite et grande échelle
          </p>
        </Col>
      </Row>
    </div>
  </div>
);

export default Activities;
