import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import uncleSam from '../../images/join-us/unclesam.jpeg';
import arrow from '../../images/png/arrow.png';

import styles from './card.module.sass';

const JoinUsCard = () => (
  <React.Fragment>
    <Row className={styles.container}>
      <Col xs={12} md={12} lg={12} className={styles.column}>
        <h1>Recrutement</h1>
      </Col>
      <Col xs={12} md={12} lg={12} className={styles.column}>
        <img src={uncleSam} className={styles.sam} />
      </Col>
      <Col xs={12} md={12} lg={12} className={styles.column}>
        <Button href="/recruitment" size="lg">
          CLIQUEZ ICI
        </Button>
      </Col>
    </Row>
  </React.Fragment>
);

export default JoinUsCard;
